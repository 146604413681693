.flip-card {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transition-delay: 0.2s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner,
.flip-card:active .flip-card-inner {
  transform: rotateY(-180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front,
.flip-card-back {
  color: black;
}

.flip-card-back {
  transform: rotateY(-180deg);
}
