.sort-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50px;

  margin-top: 20px;

  font-family: 'Lato', benton-sans, sans-serif;
  color: var(--navbar-text-color);
}
