.slant-toggle-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  overflow: hidden;

  cursor: pointer;
}

.slant-toggle-A {
  left: 0;
  width: 50%;
  height: 100%;

  z-index: 4;
}

.slant-toggle-B {
  right: 0;
  width: 80%;
  height: 100%;

  z-index: 2;
}

.slant-toggle-wrapper * {
  transition: all 0.4s ease;
}

.toggle-slant {
  position: absolute;
  top: -35px;
  left: 28%;
  width: 64px;
  height: 88px;

  transform: rotateZ(45deg);
  z-index: 3;
}
