.tag-rename-wrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 52px); /* minus navbar height */
}

.tag-rename-wrapper .tags-input {
  background-color: white;
}

.tag-rename-wrapper-left {
  float: left;
  width: 40%;
  overflow-x: hidden;

  background-color: var(--tag-rename-bg);
}

.tag-rename-wrapper-right {
  float: right;
  width: 60%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tag-rename-wrapper-right > * {
  margin: 20px;
}

.tag-rename-wrapper .from-badge {
  pointer-events: none;
  white-space: pre;

  transform: scale(1.5);
}

.tags-list-badge {
  cursor: pointer;
}

.tagify--hasMaxTags .tagify__input {
  display: none;
}

.tag-rename-submit {
  cursor: pointer;
  background-color: #c4ffd6;
  border: 1px solid green;

  padding: 6px 12px;
  border-radius: 7px;
}
