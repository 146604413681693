.modal-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.7);
}

.modal {
  position: absolute;
  width: 400px;
  height: 500px;

  top: 50%;
  transform: translateY(-50%);

  padding: 30px;
  border: 1px solid var(--form-control-border-color);
  border-radius: 20px;

  backdrop-filter: saturate(50%) blur(20px);
  background-color: rgba(0,0,0,0.4);
  color: white;

  font-family: "Lato",benton-sans,sans-serif;
  font-size: 20px;
}
