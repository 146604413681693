#recipeWrapper {
  padding-left: 25%;
  padding-right: 25%;
  padding-bottom: 5%;
  font-family: 'Open_Sans', benton-sans, sans-serif;
  color: var(--recipe-text-color);
  width: 100%;
  position: relative;
  opacity: 1;
  overflow: hidden;
  transition: padding 0.5s, opacity 0.5s;
}

#recipeWrapper #recipeTitle {
  font-family: 'Lato', caecilia, sans-serif;
  font-weight: bold;
  font-size: 42px;
}

#dotSeparator {
  padding-left: 5px;
  padding-right: 5px;
}

#recipeWrapper #subTitle {
  position: absolute;
  padding-top: 3px;
  padding-bottom: 15px;
  font-variant: small-caps;
  float: left;
}

#recipeWrapper #subTitle #servingsNumber {
  font-size: 0.85rem;
}

#recipeWrapper #ingredientsColumn .badge-list {
  display: initial;
}

#recipeWrapper #stepsColumn .badge-list {
  display: none;
}

#recipeWrapper .badge-list a.tag-link {
  color: white;
  text-decoration: none;
}

#recipeWrapper.animation-enter {
  opacity: 0.01;
}
#recipeWrapper.animation-enter-active {
  opacity: 1;
}
#recipeWrapper.animation-enter-done {
  opacity: 1;
}

#recipeWrapper #recipeBody {
  display: flex;
  flex-wrap: wrap;

  font-size: 14px;
}

#recipeWrapper .ingredients-title {
  font-weight: bold;
  font-size: 16px;
}

#recipeWrapper #ingredientsColumnSticky {
  position: sticky;
  position: -webkit-sticky;
}

#recipeWrapper #ingredientsColumn ul {
  padding-inline-start: 0;
  list-style-type: none;
}

#recipeWrapper #ingredientsColumn ul li {
  line-height: 35px;
}

#recipeWrapper #ingredientsColumn {
  width: 40%;

  padding-right: 20px;
  margin-bottom: 20px;
}

#recipeWrapper #stepsColumn {
  width: 60%;
}

#recipeWrapper #stepsColumn a {
  color: var(--recipe-text-color);
}

#recipeWrapper #ingredientsColumn .tagTitle,
#recipeWrapper #stepsColumn .tagTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 4px;
}

#recipeWrapper #stepsColumn ul {
  padding-inline-start: 0;
  list-style-type: none;

  margin-top: 0;
}

#recipeWrapper #stepsColumn ul li {
  padding-bottom: 30px;
}

#recipeWrapper #stepsList .stepTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 3px;
}

#recipeWrapper .sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
}

#recipeWrapper .add-to-cart {
  width: 100%;
  height: 52px;
  margin: 10px 0 10px 0;

  background-color: brown;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

#recipeWrapper .in-cart {
  width: 100%;
  height: 52px;
  margin: 10px 0 10px 0;

  background-color: green;
  cursor: pointer;

  color: black;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1500px) {
  #recipeWrapper {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media screen and (max-width: 1000px) {
  #recipeWrapper {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (max-width: 559px) {
  #recipeWrapper #ingredientsColumn .badge-list {
    display: none;
  }

  #recipeWrapper #stepsColumn .badge-list {
    display: inherit;
  }

  #recipeWrapper .sticky {
    position: relative;
    top: 0px;
  }

  #recipeWrapper #ingredientsColumn {
    width: 100%;
  }

  #recipeWrapper #stepsColumn {
    width: 100%;
  }
}
