.modal.sign-in-modal .modal-close-button {
  position: absolute;
  top: 30px;
  right: 30px;

  transform: scale(1.2);

  cursor: pointer;
}

.modal.sign-in-modal .modal-close-button svg path,
.modal.sign-in-modal .modal-close-button svg polygon,
.modal.sign-in-modal .modal-close-button svg rect {
  fill: #ccc;
}


.modal.sign-in-modal .form-control {
  background-color: black;
  border: 1px solid grey;
}

.modal.sign-in-modal {
  border: 1px solid grey;
}