.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  border: 1px solid var(--badge-border);

  color: #fff;
}

.badge.badge-primary {
  background-color: #007bff;
}

.badge.badge-info {
  background-color: #17a2b8;
}

.badge a {
  color: white;
  text-decoration: none !important;
}

.badge-list .badge {
  margin: 3px;
  display: inline-block;
}
