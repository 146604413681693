#recipeGrid .grid-card {
  display: inline-block;
  position: relative;
  box-sizing: border-box;

  width: 20%;
  height: 260px;

  color: inherit;
  text-decoration: none;
}

#recipeGrid .grid-card .grid-card-image {
  object-fit: cover;
  width: 100%;
  height: 100%;

  border: 4px solid var(--background-color);
  border-radius: 30px;

  opacity: 0;

  transition: 0.25s opacity;
  transition-delay: var(--delay);
}

#recipeGrid.loaded .grid-card .grid-card-image {
  opacity: 1;
}

#recipeGrid .grid-card .grid-card-back {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  border-radius: 30px;
  padding: 6px;

  background-color: var(--section-color);
}

#recipeGrid .grid-card .grid-card-back-color {
  position: absolute;
  background-color: var(--section-color);
  width: 100%;
  height: 100%;
  opacity: 0.6;

  border-radius: inherit;
}

#recipeGrid .grid-card .grid-card-title {
  position: absolute;
  text-align: center;
  font-size: 20px;

  background-color: gray;
  color: white;
  border-radius: 8px;
  width: 80%;
  padding: 10px;
}

@media screen and (max-width: 500px) {
  #recipeGrid .grid-card {
    width: 50%;
  }
}

@media screen and (min-width: 500px) {
  #recipeGrid .grid-card {
    width: 33%;
  }
}

@media screen and (min-width: 850px) {
  #recipeGrid .grid-card {
    width: 25%;
  }
}

@media screen and (min-width: 1000px) {
  #recipeGrid .grid-card {
    width: 20%;
  }
}
