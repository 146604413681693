.card-bookmark {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 23px;
  padding: 5px;

  font-size: 13px;
  font-weight: 700;
  font-variant: small-caps;
  white-space: pre;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  position: absolute;
  top: 8px;
  right: 0;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  color: #fff;
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 30%);
}

.card-bookmark.new {
  background-color: #007bff;
}

.card-bookmark.info {
  background-color: brown;
}

.card-bookmark.star {
  background-color: #f57c00;
}

.card-bookmark svg {
  width: 17px;
  height: 17px;
}

.card-bookmark svg path,
.card-bookmark svg rect,
.card-bookmark svg polygon {
  fill: white;
}
