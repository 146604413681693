.form-group {
  margin: 30px 0 30px 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--form-text-color);
  background-color: var(--form-control-bg);
  background-clip: padding-box;
  border: 1px solid var(--form-control-border-color);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  font-family: inherit;
}

textarea.form-control {
  height: auto;
  overflow: auto;
  resize: vertical;
  margin: 0;
}

.form-control-file {
  margin-left: 10px;
}

.form-label {
  display: inline-block;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 5px;
  font-size: inherit;
}

.form-control:disabled {
  background-color: var(--form-control-bg);
}

.form-control:focus {
  background-color: initial;
  color: var(--form-text-color);
}

select {
  background-color: var(--form-select-bg);
  color: var(--form-text-color);

  width: calc(100% - 200px);
  padding: 6px;
}

#formWrapper {
  padding: 40px 100px 40px 100px;
  transition: 0.5s padding;
  color: var(--form-text-color);
}

@media screen and (max-width: 559px) {
  #formWrapper {
    padding: 10px;
  }
}
#recipePicker {
  margin-left: 100px;
  margin-top: 80px;
  transition: 0.5s margin;
}

@media screen and (max-width: 559px) {
  #recipePicker {
    margin-left: 5px;
    margin-top: 10px;
  }
}

.tags-look .tagify__dropdown__item {
  display: inline-block;
  border-radius: 3px;
  padding: 0.3em 0.5em;
  border: 1px solid #ccc;
  background: #f3f3f3;
  margin: 0.2em;
  font-size: 0.85em;
  color: black;
  transition: 0s;
}

.tags-look .tagify__dropdown__item--active {
  color: black;
}

.tags-look .tagify__dropdown__item:hover {
  background: lightyellow;
  border-color: gold;
}
