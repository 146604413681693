.alerts-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;

  pointer-events: none;
}

.alert {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  position: relative;
  top: 0;
  opacity: 0;
  transition: 0.3s top ease, 0.3s opacity;

  width: 50%;
  left: 50%;
  transform: translateX(-50%);
  margin: 10px 0;

  padding: 10px;

  background-color: #4da74d;
  border: 1px solid white;
  border-radius: 5px;
  color: white;

  text-align: center;

  pointer-events: all;
}

.alert-close {
  position: absolute;
  left: 10px;

  cursor: pointer;
}

.alert-text {
  position: relative;
  left: 40px;
  width: calc(100% - 50px);
}

.alert.error {
  background-color: #ff5757;
  border: 1px solid white;
  color: white;
}

.alert.status {
  background-color: #bbb;
  border: 1px solid black;
  color: black;
}

.alert.animation-enter,
.alert.animation-enter-active,
.alert.animation-enter-done {
  top: 80px;
  opacity: 1;
}
