#numRecipesCounter {
  color: gray;
  font-family: Lato, benton-sans, sans-serif;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}

#recipeCardDeck {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  align-content: space-around;
}
