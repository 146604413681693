.modal.are-you-sure-modal {
  width: 400px;
  max-width: 80%;
  height: 200px;
  font-size: 24px;
  text-align: center;

  padding: 10px;
}

.are-you-sure-modal form {
  display: flex;
  justify-content: center;
  position: relative;

  top: 30px;
}

.are-you-sure-modal button {
  margin: 20px;
  padding: 4px 12px;
  border: none;
  border-radius: 5px;

  font-size: 16px;
  color: white;
}

.are-you-sure-modal #areYouSureCancel {
  background-color: indianred;
}

.are-you-sure-modal #areYouSureSubmit {
  background-color: yellowgreen;
}
