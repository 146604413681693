#bannerWrapper {
  position: relative;
  height: calc(80vh - 52px); /* navbar size */
  top: -8px; /* not sure */
  transition: 0.8s height;
}

#bannerWrapper.searching {
  height: 180px;
}

#bannerWrapper #bannerImage {
  height: 100%;
  width: 100%;
  opacity: 0;
  position: relative;

  object-fit: cover;

  display: block;
  transition: 0.5s opacity ease-in-out;
  transition-delay: 1.2s;
}

#bannerWrapper #bannerImage.animation-enter,
#bannerWrapper #bannerImage.animation-enter-active,
#bannerWrapper #bannerImage.animation-enter-done {
  opacity: 1;
}

#bannerWrapper #bannerTitle {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
  padding: 5px;
  background-color: rgb(0, 0, 0, 0.4);
  border-radius: 5px;
  max-width: 90%;

  font-family: 'Open_Sans', benton-sans, sans-serif;
}

#bannerWrapper #bannerTitle a {
  color: inherit;
  text-decoration: none;
}

.search-wrapper {
  position: absolute;
  display: flex;
  top: 0;

  height: 100%;
  width: 100%;

  font-family: 'Open_Sans', benton-sans, sans-serif;
}

.searchBar-wrapper {
  position: relative;
  display: flex;
  margin: auto;
  min-width: 284px;
  width: 30%;
  padding: 0 22px 0 22px;
  height: 75px;
  background-color: var(--searchbar-wrapper-bg);
  backdrop-filter: saturate(200%) blur(4px);
  -webkit-backdrop-filter: saturate(200%) blur(4px);
  border-radius: 8px;
}

#searchBar {
  display: block;
  position: relative;
  margin: auto;
  width: 100%;
  height: 50%;
  background-color: var(--searchbar-bg);

  border: none;
  border-radius: 10px;
  padding: 10px;
  color: var(--form-text-color);
}

input#searchBar:focus-visible {
  outline-width: 0;
}

input#searchBar::placeholder {
  color: gray;
}

@media screen and (max-width: 500px) {
  #bannerWrapper.searching #bannerTitle {
    display: none;
  }

  #bannerWrapper.searching {
    height: 80px;
  }
}

@media screen and (min-width: 850px) {
  #bannerWrapper #bannerTitle {
    right: 10px;
    left: unset;
    transform: unset;
    bottom: 10px;
    text-align: center;
    padding: 5px;
    margin: auto;
  }

  #bannerWrapper {
    height: calc(70vh - 56px);
  }

  #bannerWrapper.searching #bannerTitle {
    display: none;
  }

  #bannerWrapper.searching {
    height: 100px;
  }
}
