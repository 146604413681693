* {
  box-sizing: border-box;
}

body {
  position: relative;
  padding-top: 60px;
  overflow-x: hidden !important;
  max-width: 100vw;

  background-color: var(--background-color);

  --background-color: #ddd;

  --navbar-bg-color: rgba(180, 180, 180, 0.8);
  --navbar-text-color: #000;

  --svg-color: black;

  --searchbar-bg: white;
  --searchbar-wrapper-bg: rgb(255, 255, 255, 0.5);

  --recipe-card-title-color: rgb(50, 50, 50);
  --recipe-card-box-shadow: 0 0 12px 0px lightgrey;
  --recipe-card-bg: #eee;

  --recipe-text-color: #4a4a4a;

  --form-text-color: initial;
  --form-control-bg: initial;
  --form-control-border-color: black;
  --form-select-bg: initial;

  --badge-border: black;

  --tag-rename-bg: lightgray;

  --word-cloud-hover: black;

  --Appetizers-color: #c2185b; /* pink */
  --Breakfast-color: #7b1fa2; /* purple */
  --Dessert-color: #1976d2; /* blue */
  --Sauces-color: #f57c00; /* orange */
  --Entrees-color: #009688; /* teal */
  --Salad-color: #689f38; /* light green */
  --Seasoning-color: #d32f2f; /* red */
  --Sides-color: #455a64; /* blue gray */
  --Drinks-color: #795548; /* brown */
  --Snacks-color: #ffc107; /* dark yellow */
  --Soup-color: #6d6d6d; /* dark gray */
}

body.dark-theme {
  --background-color: #0d0d0f;

  --navbar-bg-color: rgba(29, 29, 35, 0.8);
  --navbar-text-color: #fff;

  --svg-color: #fff;

  --searchbar-bg: black;
  --searchbar-wrapper-bg: rgba(0, 0, 0, 0.5);

  --recipe-card-title-color: rgb(200, 200, 200);
  --recipe-card-box-shadow: none;
  --recipe-card-bg: #242424;

  --recipe-text-color: #eeeeee;

  --form-text-color: rgb(240, 240, 245);
  --form-control-bg: black;
  --form-control-border-color: #7d7d7d;
  --form-select-bg: #242424;

  --badge-border: white;

  --tag-rename-bg: darkslategray;

  --word-cloud-hover: white;
}

svg path,
svg rect,
svg polygon {
  fill: var(--svg-color);
}

@font-face {
  font-family: 'Bebas_Neue';
  src: local('Bebas_Neue'),
    url(./fonts/Bebas_Neue/BebasNeue-Regular.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Open_Sans';
  src: local('Open_Sans'),
    url(./fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Neucha';
  src: local('Neucha-Regular'),
    url(./fonts/Neucha/Neucha-Regular.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato-Regular'),
    url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Satisfy';
  src: local('Satisfy-Regular'),
    url(./fonts/Satisfy/Satisfy-Regular.ttf) format('truetype');
  font-display: block;
}
