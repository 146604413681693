.nutrition {
  display: inline-block;
  cursor: default;
}

.nutrition .nutrition-info {
  color: white;

  font-size: 0.7em;
  font-weight: bold;
  border-radius: 5px 10px;
  padding: 3px 4px;
  margin: 3px;
  white-space: pre;
}

.nutrition .nutrition-info.vegan {
  background-color: var(--Salad-color);
}

.nutrition .nutrition-info.vegetarian {
  background-color: var(--Entrees-color);
}

.nutrition .nutrition-info.dairy-free {
  background-color: var(--Dessert-color);
}

.nutrition .nutrition-info.gluten-free {
  background-color: var(--Drinks-color);
}

.nutrition .nutrition-price {
  font-size: 0.85em;
  font-variant: small-caps;

  margin-right: 5px;
}

.nutrition .nutrition-error {
  background-color: tomato;
}
