#recipeCardDeck .recipe-card.animation-enter,
#recipeCardDeck .recipe-card.animation-enter-active,
#recipeCardDeck .recipe-card.animation-enter-done {
  opacity: 1;
}

#recipeCardDeck .recipe-card {
  position: relative;
  margin: 10px;
  width: 80%;
  height: 260px;
  max-height: 100vw;
  max-width: 350px;
  border-radius: 10px;
  box-shadow: var(--recipe-card-box-shadow);
  background-color: var(--recipe-card-bg);
  opacity: 1;
  text-decoration: none;
  overflow: hidden;
  transition: 0.25s opacity, 0.8s width;
  transition-delay: var(--delay);
}

#recipeCardDeck .recipe-card .recipe-image-wrapper {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 160px;
}

#recipeCardDeck .recipe-card .recipe-image {
  object-fit: cover;
  transition: 1s transform;
  width: 100%;
  height: 160px;
}

#recipeCardDeck .recipe-card .recipe-section {
  color: var(--section-color);
  font-family: 'Open_Sans', benton-sans, sans-serif;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
}

#recipeCardDeck .recipe-card .recipe-title {
  color: var(--recipe-card-title-color);
  font-family: 'Lato', benton-sans, sans-serif;
  font-size: 16px;
  text-align: left;
  margin-top: 4px;
}

#recipeCardDeck .recipe-card:hover {
  text-decoration: none;
}

#recipeCardDeck .recipe-body {
  position: relative;
  height: calc(100% - 160px);
  width: 100%;
  top: -2px; /* not sure */
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  border-top: 3px solid var(--section-color);
}

#recipeCardDeck .recipe-card:hover img {
  transform: scale(1.05);
}

@media screen and (min-width: 500px) {
  #recipeCardDeck .recipe-card {
    width: 40%;
  }
}

@media screen and (min-width: 850px) {
  #recipeCardDeck .recipe-card {
    width: 30%;
  }
}

@media screen and (min-width: 1000px) {
  #recipeCardDeck .recipe-card {
    width: 20%;
  }
}
