#groceriesContainer .cart-empty {
  color: var(--recipe-text-color);
  text-align: center;
  height: 300px;
  padding-top: 150px;
}

#groceriesContainer svg.grocery-recipe-x path {
  fill: white;
}

#groceriesContainer .groceries-ingredients {
  margin-top: 20px;
  margin-left: 20px;
}

#groceriesContainer .ingredient {
  color: var(--recipe-text-color);
}

#groceriesContainer #copy-ingredients {
  display: flex;
  align-items: center;
  justify-content: center;
}

#groceriesContainer #copy-ingredients svg {
  cursor: pointer;
}

#groceriesContainer .clear-from-cart {
  position: absolute;
  top: 8px;
  left: 8px;

  width: 24px;
  height: 24px;
  transform: scale(1.3);

  border-radius: 50%;
  background-color: red;
  color: white;
  text-align: center;
}
