.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 52px;
  z-index: 2;

  backdrop-filter: saturate(200%) blur(20px);
  -webkit-backdrop-filter: saturate(200%) blur(20px);
  background-color: var(--navbar-bg-color);

  /* Taken from bootstrap navbar*/
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar.shopping {
  background-color: green;
}

.navbar .menu-toggler {
  color: gray;
  font-size: 24px;
  background-color: transparent;

  width: 40px;
  text-align: center;

  transform: translateZ(50px); /* Safari hack for rotateY */

  z-index: 3;
}

.navbar .menu-toggler svg {
  transition: transform 0.6s;
  transform-origin: center;
}

.navbar .menu-toggler.open svg {
  transform: rotateY(180deg);
}

/* Center the logo up top */
.navbar-brand {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.1s opacity;

  font-family: 'Satisfy';

  text-decoration: none;
  font-size: 30px;

  margin-top: 1px;

  color: var(--navbar-text-color);
  width: calc(100% - 170px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.navbar-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-right > * {
  margin: 0 10px 0 10px;
  width: 38px;
  height: 38px;
}

.navbar-button {
  cursor: pointer;
}

.cart {
  width: 24px;
  height: 24px;

  cursor: pointer;
}

.cart-count {
  position: absolute;
  background-color: aquamarine;

  top: 3px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
}

.navbar-logged-in {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #638819;
}

.navbar-menu {
  position: fixed;
  height: calc(100vh - 52px);
  width: 250px;
  top: 52px;
  left: -250px;
  opacity: 0;
  z-index: 2;

  font-family: 'Lato', benton-sans, sans-serif;
  font-size: 20px;

  transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;

  box-shadow: 12px 0 15px -4px rgb(0, 0, 0, 0.6);
  backdrop-filter: saturate(200%) blur(20px);
  -webkit-backdrop-filter: saturate(200%) blur(20px);
  background-color: var(--navbar-bg-color);
}

.navbar-menu.navbar-menu-visible {
  left: 0;
  opacity: 1;
}

.navbar-menu .navbar-menu-list {
  list-style-type: none;
  padding: 20px 5px 30px 5px;
  margin-top: 5px;

  overflow-y: scroll;
  height: calc(100% - 65px); /* Magic number */
  -webkit-overflow-scrolling: touch;
}

.navbar-menu .navbar-menu-link {
  display: flex;
  align-items: center;

  color: var(--navbar-text-color);
  text-decoration: none;
}

.navbar-menu .nav-item {
  display: flex;
  align-items: center;

  line-height: 26px;

  padding: 0.3rem 0 0.3rem 1rem;

  transition: transform 0.3s;
  transform-origin: left;
}

.navbar-menu .nav-item:hover {
  transform: scale(1.1);
}

.navbar-menu .nav-item svg {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.navbar svg.login-svg path,
.navbar svg.login-svg rect,
.navbar svg.login-svg polygon {
  fill: gray;
}

.navbar-menu .nav-item.separator {
  border-bottom: 1px solid var(--navbar-text-color);
  margin: 15px 20px 20px 20px;
  opacity: 0.2;
}

.navbar-menu .settings-holder {
  position: relative;
  width: 100%;
  height: 60px;

  top: -20px;
  bottom: 0;

  background-color: rgba(255, 255, 255, 0.2);
}
